/* snackbar start */

#snackbar {
    visibility: hidden;
    width: 80px;
    margin-left: -100px;
    background-color: rgba(255, 255, 255, 0.03);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    position: fixed;
    z-index: 99;
    right: 30px;
    color: white;
    top: 250px;
    font-size: 15px;
    font-family: system-ui;
    font-weight: 600;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}



#copie {
    visibility: hidden;


}

#copie.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        right: 0;
        opacity: 0;
    }

    to {
        right: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        right: 0;
        opacity: 0;
    }

    to {
        right: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        right: 30px;
        opacity: 1;
    }

    to {
        right: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        right: 30px;
        opacity: 1;
    }

    to {
        right: 0;
        opacity: 0;
    }
}

/* snackbar end */

.accountModal {
    background-color: #221f1d;
    padding: 30px;
    border-radius: 12px;
    /* box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 10%); */
}

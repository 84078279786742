.refcardsWrapper {
  background: #1c1a19;
  border: 2px solid #2e2a28;
  border-radius: 12px;
 
  z-index: 0;
  position: relative;
  .card_child {
    width: 100%;
  }
  .gameCard {
    width: 100%;
    background-color: transparent !important;
    color: #fff !important;
    border: none !important;
    max-width: unset !important;
    box-shadow: none !important;
    margin: 0 !important;
  }
  .referCardHeading {
    background: #221f1d;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 26px;
    box-sizing: border-box;
    color: #fff;
  }
  .refNameWrapper {
    .refName {
      color: #382b22;
      opacity: 1 !important;
      font-size: 60px !important;
      text-align: center;
      position: relative !important;
      line-height: 1.2 !important;
      h5 {
        color: #382b22;
        margin: 0;
        margin-top: 6px;
        font-size: 16px;
        text-align: center;
      }
    }
    .initiatorsName {
      color: #382b22;
      opacity: 1 !important;
      font-size: 60px !important;
      text-align: center;
      position: relative !important;
      line-height: 1.2 !important;
      h5 {
        color: #382b22;
        margin: 0;
        margin-top: 6px;
        font-size: 16px;
        text-align: center;
      }
    }    
    img {
      display: none;
    }
  }

  .refBottomWrapper {
    background: #221f1d;
    padding: 10px 26px;
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .entryPriceWrapper {
      font-size: 60px;
      width: 100%;
      h6 {
        color: rgba(199, 185, 178, 0.8);
        margin: 0;
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }
  .refBottomBtnWrapper {
    width: 100%;
    margin-top: 30px;
    .orange_btn {
      width: 100%;
    }
  }
}



.initiatorsWrapper {
  background: #1c1a19;
  border: 2px solid #2e2a28;
  border-radius: 12px;
  width: 100% !important;
  z-index: 0;
  position: relative;
  .card_child {
    width: 100%;
  }
  .gameCard {
    width: 100%;
    background-color: transparent !important;
    color: #fff !important;
    border: none !important;
    max-width: unset !important;
    box-shadow: none !important;
    margin: 0 !important;
  }
  .initiatorsCardHeading {
    background: #221f1d;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 26px;
    box-sizing: border-box;
    color: #fff;
  }
  .initiatorsNameWrapper {
    .initiatorsName {
      color: #b6a49a66;
      opacity: 1 !important;
      font-size: 60px !important;
      text-align: center;
      position: relative !important;
      line-height: 1.2 !important;
      h5 {
        color: rgba(182, 164, 154, 0.4);
        margin: 0;
        margin-top: 6px;
        font-size: 16px;
        text-align: center;
      }
    }
    img {
      display: none;
    }
  }
  .initiatorsBottomWrapper {
    background: #221f1d;
    padding: 10px 26px;
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .entryPriceWrapper {
      font-size: 60px;
      width: 100%;
      h6 {
        color: rgba(199, 185, 178, 0.8);
        margin: 0;
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }
  .initiatorsBottomBtnWrapper {
    width: 100%;
    margin-top: 30px;
    .orange_btn {
      width: 100%;
    }
  }
}

.initiatorxs_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  .bxwrapper {
    padding-right: 3vw;
    margin-right: 3vw;
    border-right: 1px solid rgba(65, 59, 55, 0.4);
    &:last-child {
      padding-right: 0px;
      margin-right: 0px;
      border-right: 0;
    }
    .heading {
      color: rgba(199, 185, 178, 0.8);
      font-size: 16px;
    }
    .value {
      font-size: 24px;
      color: #fff;
    }
  }
  .orange_btn {
    background: #d9511c !important;
    border: 0 !important;
    display: flex !important;
    align-items: center !important;
    svg {
      margin-right: 5px;
    }
  }
}

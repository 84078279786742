.m-0 {
  margin: 0 !important;
}

.m-30 {
  margin: 30px !important;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-6rem {
  margin-top: 6rem;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.p-10 {
  padding: 10px !important;
}

.wider-width {
  width: calc(100vw - 80px);
}

.cursor {
  cursor: pointer;
}

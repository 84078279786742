@keyframes react-overlay-loader-spinner {
  to {
    transform: rotate(360deg);
  }
}

.react-overlay-loader-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -30px;
  margin-left: -20px;
  border-radius: 50%;
  border: 3px solid #eee;
  border-top-color: #07d;
  animation: react-overlay-loader-spinner 0.8s linear infinite;
}

.card_group {
  margin-top: 30px;
  //max-height: 80vh;
  padding: 50px;
  border-radius: 20px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.03);
  box-shadow:
    0 4px 10px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.gameCard {
  width: 180px;
  height: auto;
  border-radius: 10px;
  // padding: 10px;
  box-shadow:
    0 4px 10px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  img {
    width: 130px;
  }
  z-index: 0;
}

/*
.card_parent_Controllers {
    border: 1px solid #2f80ed;
    padding: 1px;
    margin-right: 10px;
    border-radius: 10px;
    width: fit-content;
}

.card_child_Controllers {
    border: 1px solid #2f80ed;
    padding: 2px;
    border-radius: 10px;
}


.card_parent_Initiators {
    border: 1px solid #11f145;
    padding: 1px;
    margin-right: 10px;
    border-radius: 10px;
    width: fit-content;
}

.card_child_Initiators {
    border: 1px solid #11f145;
    padding: 2px;
    border-radius: 10px;
}
*/

.card_parent_1_Duelists {
  // border: 1px solid #f0677e;
  padding: 1px;
  margin-left: 1;
  border-radius: 10px;
  width: fit-content;
}

.card_parent_Sentinels {
  // border: 1px solid #8e2de2;
  padding: 1px;
  margin-right: 10px;
  border-radius: 10px;
}

.card_child_Sentinels {
  // border: 1px solid #8e2de2;
  background-color: transparent !important;
  padding: 2px;
  border-radius: 10px;
}

.card_1_Duelists {
  background: #ffe9e9;
  background: -webkit-linear-gradient(to left, #39292b, #f8b6c1);
  background: linear-gradient(to left, #39292b, #f8b6c1);
  border: 3px solid pink;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;
}

.card_0_Duelists {
  background-color: #ffe9e9;
  color: #382b22;
  border: 3px solid pink;
}

.card_5_Duelists {
  background-color: #bdc678;
  color: #382b22;
  border: 3px solid rgb(183, 208, 60);
}

.card_4_BonusYielder {
  background-color: #ecbd83;
  color: #382b22;
  border: 3px solid rgb(243, 178, 59);
}

.card_0_Genesis {
  background: #ffe9e9;
  background: -webkit-linear-gradient(to left, #3d3c3c, #f8f5f5);
  background: linear-gradient(to left, #3d3c3c, #f8f5f5);
  border: 3px solid pink;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;
}

.card_Referrers {
  background: #f12711;
  background: -webkit-linear-gradient(to left, #f5af19, #f12711);
  background: linear-gradient(to left, #f5af19, #f12711);
  border: 2px solid orange;
}

.card_1_Initiators {
  background: #1d796a;
  background: -webkit-linear-gradient(to left, #073010, #1ec645);
  background: linear-gradient(to left,  #073010, #1ec645);
  border: 2px solid #1d796a;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;

}

.card_5_Duelists {
  background-color: #bdc678;
  color: #382b22;
  border: 3px solid #b7d03c;
}

.card_4_BonusYielder {
  background-color: #ecbd83;
  color: #382b22;
  border: 3px solid #f3b23b;
}
.card_5_Duelists {
  background: #1d796a;
  background: -webkit-linear-gradient(to left, #073010, #e4f87e);
  background: linear-gradient(to left,  #073010, #e4f87e);
  border: 2px solid #1d796a;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;
}

.card_4_BonusYielder {
  background: #1d796a;
  background: -webkit-linear-gradient(to left, #221908, #f3b23b);
  background: linear-gradient(to left,  #221908, #f3b23b);
  border: 2px solid #1d796a;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;
}
.card_0_Initiators {
  background: #11f145;
  color: #8ff49e;
  border: 3px solid pink;
}

.card_5_Initiators {
  background: #11f145;
  color: #8ff49e;
  border: 3px solid rgb(183, 208, 60);
}


.card_0_Initiatorx {
  background: #11f145;
  background: -webkit-linear-gradient(to left, #1d796a, #11f145);
  background: linear-gradient(to left, #1d796a, #11f145);
  border: 2px solid #1d796a;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;
  text-shadow:
    0px 0px 10px #6eee92,
    0px 0px 15px #13db23,
    0px 0px 30px #00ff6299;
}

.card_1_Initiatorx {
  background: #11f145;
  background: -webkit-linear-gradient(to left, #1d796a, #11f145);
  background: linear-gradient(to left, #1d796a, #11f145);
  border: 2px solid #1d796a;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: #8ff49e;
  text-shadow:
    0px 0px 10px #6eee92,
    0px 0px 15px #13db23,
    0px 0px 30px #00ff6299;
}

.card_3_Controllers {
  background: #56ccf2;
  background: -webkit-linear-gradient(to left, #0e2545, #56ccf2);
  background: linear-gradient(to left, #0e2545, #56ccf2);
  border: solid 2px transparent;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: aqua;

}

.card_3_Controllers::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 10px;
}

.card_0_Initiators::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 10px;
}

.card_0_Sentinels {
  background: #8e2de2;
  background: -webkit-linear-gradient(to left, #4a00e0, #8e2de2);
  background: linear-gradient(to left, #4a00e0, #8e2de2);
  border: solid 2px transparent;
  position: relative;
  background-clip: padding-box;
  margin-right: 0 !important;
  color: white;
  text-shadow:
    0px 0px 10px #ffffff,
    0px 0px 15px #ffffff,
    0px 0px 30px #cbb1ff;
}

.card_0_Sentinels::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 10px;
}

.card_icon {
  position: absolute;
  right: 2px;
  top: 2px;
  border-radius: 50%;
}

@media screen and (max-width: 600px) {
  .card_group {
    padding: 20px 30px;
    margin: 30px 10px 10px;
  }
  .gameCard {
    width: 250px;
  }
  .card_parent_Controllers {
    margin-left: 10px;
  }
}

.container {
  margin: 0 auto;
}

.swiper-container {
  width: 100%;
  max-height: 100vh;
  // CSS Grid/Flexbox bug size workaround
  // @see https://github.com/kenwheeler/slick/issues/982
  min-height: 0;
  min-width: 0;
}

.wrapper {
  display: grid;
  //place-items: center;
  min-height: auto;
  font-family: "Montserrat", sans-serif;
  background-color: var(--second-color);
}

.swiper-wrapper {
  width: min(1500px);
  max-height: 100%;
  height: 100%;
  display: flex;
}

.swiper {
  border-radius: 10px;
}

.swiper-slide {
  width: auto;
  max-width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}

.swiper-slide_first {
  max-width: auto;
  //max-width: 350px
}

.swiper-slide_second {
  max-width: 220px;
  min-width: 220px;
  //width: 110px
}

.swiper-slide_third {
  max-width: 600px;
}

/* We use it if necessary so that the width of the slides is larger than the original one */
.swiper-slide_size_m {
  width: 48.75%;
}

.swiper-slide_size_l {
  width: 74.375%;
}

.App {
  max-width: 100vw;
  overflow: hidden;
}

a {
  color: #fff;
  text-decoration: none;
}

.reactive-btn {
  box-shadow: 0px 5px 16px -3px var(#d9511c) !important;
  background-color: #d9511c !important;
  border-radius: 8px !important;
  &.primary.outline {
    color: #d9511c !important;
    border-color: #d9511c !important;
  }
}

.widthsection {
  padding-right: 160px;
  padding-left: 160px;
  @media screen and (max-width: 1250px) {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  @media screen and (max-width: 991px) {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  @media screen and (max-width: 600px) {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.ReactModal__Content {
  background: transparent !important;
}
.MuiFilledInput-input {
  padding: 12px 18px !important;
}
.MuiInputBase-root.MuiFilledInput-root:after {
  border-bottom: 2px solid #ff6e36 !important;
}

button:disabled {
  background-color: rgba(255, 110, 54, 0.3) !important;
  color: rgba(255, 255, 255, 0.6) !important;
}
